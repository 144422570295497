import React from "react";
import "./Container.css";
import image1 from "../assets/1.png";
import image2 from "../assets/2.png";
import image3 from "../assets/3.png";
import image22 from "../assets/1 2.png";
import image21 from "../assets/2 1.png";
import image31 from "../assets/3 1.png";
import image32 from "../assets/3 2.png";
import image33 from "../assets/3 3.png";
import image4 from "../assets/4.png";
import image61 from "../assets/6 1.png";
import image62 from "../assets/6 2.png";

import bar1 from "../assets/bar 1.png";

import wave from "../assets/wave 1.png";
import barBoss from "../assets/bar-boss-blue 1.png";
import suiLogo from "../assets/sui-logo2 1.png";
import Footer from "./Footer";

const Container = () => {
    return (
        <div className="bg">
            <div className="outer-container">
                <div className="inner-container1">
                    <div className="images">
                        <img className="image1" src={image1} alt="Image 1" />
                        <img className="image2" src={image2} alt="Image 2" />
                        <img className="image3" src={image3} alt="Image 3" />
                    </div>
                    <div className="headline">Liquor</div>
                    <div className="sub-headline">Be water, be liquor, my friend</div>
                    <div className="sub-headline-description">
                        Welcome to Liquor, the memecoin that's here to bring the party to the Sui!
                        <br />
                        <br />
                        The concept behind Liquor is simple –A liquor is like water with a fun
                        twist, but can bring huge liquidity to the ecosystem as people love it!
                        <br />
                        <br />
                        Our goal is to bring more fun and build meme culture to Sui.
                    </div>
                    <div className="waves">
                        <img className="wave" style={{ marginTop: "10px" }} src={wave} />
                        <img className="wave" style={{ marginTop: "-25px" }} src={wave} />
                        <img className="wave" style={{ marginTop: "-25px" }} src={wave} />
                    </div>
                    <img
                        style={{ marginTop: "0px", width: "100%", height: "auto" }}
                        src={barBoss}
                    />
                </div>
                <div className="content-container1" style={{ height: "1100px" }}>
                    <img
                        style={{ marginTop: "10px", width: "200px", height: "auto" }}
                        src={suiLogo}
                    />
                    <div className="text-container">
                        <div className="text-headline">About Liquor</div>
                        <div className="text-content" style={{ paddingLeft: "30px" }}>
                            Liquor has been living in the crypto space since 2019, with its primary
                            residence in Solana.
                            <br />
                            <br />
                            The attention shifted to Sui after Suibasecamp in Paris where many
                            people believe that Sui has the potential to become the next Solana. I
                            share that belief.
                        </div>
                    </div>
                    <img
                        style={{
                            transform: "translate(-70%, -70%)",
                            width: "700px",
                            height: "auto",
                        }}
                        src={image22}
                    />
                    <img
                        style={{
                            transform: "translate(75%, -160%)",
                            width: "700px",
                            height: "auto",
                        }}
                        src={image21}
                    />
                </div>
                <div
                    className="content-container1"
                    style={{ marginTop: "-300px", overflow: "unset", height: "700px" }}
                >
                    <div className="text-container" style={{ paddingLeft: "80px" }}>
                        <div className="text-headline">Why Liquor?</div>
                        <div className="text-content">
                            Because we're not just another coin; we're a movement. We're blending
                            the best of meme culture with the incredible power of Sui. It's time to
                            leave the old, boring chains behind and dive into a world where your
                            investments are as entertaining as your favorite memes.
                        </div>
                    </div>
                    <img
                        style={{
                            transform: "translate(-110%, -230%)",
                            zIndex: "5",
                            width: "350px",
                            height: "auto",
                        }}
                        src={image31}
                    />
                    <img
                        style={{
                            transform: "translate(130%, -160%)",
                            width: "270px",
                            height: "auto",
                        }}
                        src={image33}
                    />
                </div>
                <div
                    className="content-container1"
                    style={{ marginTop: "-200px", overflow: "unset", height: "350px" }}
                >
                    <div className="text-container" style={{ paddingRight: "20px" }}>
                        <div className="text-content">
                            <span style={{ fontSize: "50px" }}>So,</span> are you ready to be part
                            of something epic? Join the Liquor community today and let's make waves
                            (or should we say, ripples?) on the Sui blockchain. Cheers to great
                            times and even greater liquidity!
                        </div>
                    </div>
                    <img
                        style={{
                            transform: "translate(-140%, -80%)",
                            zIndex: "5",
                            width: "350px",
                            height: "auto",
                        }}
                        src={image32}
                    />
                </div>
                <div
                    className="waves"
                    style={{
                        height: "100px",
                    }}
                >
                    <img className="wave" src={wave} />
                    <img className="wave" style={{ marginTop: "-25px" }} src={wave} />
                </div>
                <div className="content-container1" style={{ marginTop: "10px" }}>
                    {/* <img
                        style={{
                            width: "250px",
                            height: "auto",
                        }}
                        src={image4}
                    /> */}
                    <div className="address_shape">
                        <div className="address">CA:0x9c86d1926a0a39e906f20674d6a35f337be8625ebcb6b799ee8ff011f328bee2::liq::LIQ</div>
                    </div>
                    <div className="liquoromics">Liquoromics</div>
                    <div className="cards">
                        <div className="card">
                            <div className="card-image-holder">
                                <img src={bar1} alt="Total Supply" />
                            </div>
                            <div className="card-text">
                                TOTAL SUPPLY
                                <br />
                                69T
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-image-holder">
                                <img src={image2} style={{ width: "265px" }} alt="Fair Launch" />
                            </div>
                            <div className="card-text">
                                FAIR LAUNCH
                                <br />
                                100% LP
                            </div>
                        </div>
                        {/* <div className="card">
                            <div className="card-image-holder">
                                <img src={image4} style={{ width: "245px" }} alt="LP Burned" />
                            </div>

                            <div className="card-text">
                                LP BURNED
                                <br />
                                AT LAUNCH
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-image-holder">
                                <img
                                    src={image3}
                                    style={{ width: "225px" }}
                                    alt="Ownership Revoked"
                                />
                            </div>

                            <div className="card-text">
                                OWNERSHIP
                                <br />
                                REVOKED
                            </div>
                        </div> */}
                    </div>
                    <div
                        className="content-container1"
                        style={{ marginTop: "200px", height: "590px" }}
                    >
                        <img
                            style={{
                                marginTop: "150px",
                                width: "500px",
                                height: "auto",
                            }}
                            src={image61}
                        />
                        <div className="to-the-moon">To the moon</div>
                        <img
                            style={{
                                width: "500px",
                                height: "auto",
                                transform: "translate(120%, -150%)",
                            }}
                            src={image62}
                        />
                    </div>
                    <div className="waves" style={{ marginTop: "0px", height: "120px" }}>
                        <img className="wave" style={{ marginTop: "0px" }} src={wave} />
                        <img className="wave" style={{ marginTop: "-25px" }} src={wave} />
                        <img className="wave" style={{ marginTop: "-25px" }} src={wave} />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Container;
