import React from "react";
import "./Footer.css";
import f1 from "../assets/f1.png";
import f2 from "../assets/f2.png";
import f3 from "../assets/f3.png";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-icons">
                <a href="https://t.me/+oTzdJ0LyGtI2YzEx/" target="_blank">
                    <img src={f1} alt="Telegram" className="footer-icon" />
                </a>
                <a href="https://x.com/beliquoronsui/" target="_blank">
                <img src={f2} alt="X" className="footer-icon" />
                </a>
                {/* <img src={f3} alt="X" className="footer-icon" /> */}
            </div>
            <div className="footer-text">© COPYRIGHT 2024. ALL RIGHTS RESERVED BY LIQUOR ®</div>
        </div>
    );
};

export default Footer;
